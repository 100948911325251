<!--
data: 
- title: text
- subTitle: text
- text: text
- textList: list (number: number, image: image, title: text, text: richtext, url: text)
- style: select (light: text, dark: text, color: text)
-->

<template>
	<div class="cs-numerical-dialogue-block cs-block" :class="styleClass">
		<div class="cs-block-base">
			<div class="container">
				<div class="header-content">
					<h2 class="cs-title" v-if="data.title">{{ data.title }}</h2>
					<h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
					<span class="cs-text" v-html="data.text"></span>
				</div>
				<CSButton
					:data="line.url"
					v-for="(line, key) in data.textList"
					:key="key"
				>
					<p v-if="line.number" class="number">{{ line.number }}</p>
					<div class="line-item-content">
						<h4 v-if="line.title">{{ line.title }}</h4>
						<span class="cs-text" v-if="line.text" v-html="line.text" />
					</div>
				</CSButton>
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "NumericalDialogue",
	components: {
		CSButton: () => import("../components/CSButton.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {}
	},
	computed: computed('NumericalDialogue')
}
</script>
<style lang="scss" scoped>
@import "../styles/main.scss";
.cs-block.cs-numerical-dialogue-block {
	&.cs-style- {

		&dark {
			::v-deep .cs-button-container {
				&:nth-child(odd) {
                    .number {
                        color: $theme_color !important;
                    }
					.line-item-content {
                        h4 {
                            color: #fff !important;
                        }
                        span {
                            color: #fff !important;
                            * {
                                color: #fff !important;
                            }
                        }
                    }
				}

				&:nth-child(even) {
					a,
					span:not(.line-item-content span) {
						.line-item-content {
							h4 {
								color: $secondary_text_color;
							}

							.cs-text {
								color: $secondary_text_color;
								::v-deep {
									* {
										color: $secondary_text_color;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.header-content {
		text-align: center;
		margin-bottom: 10px;
	}
	.header-content {
		text-align: center;
		margin-bottom: 10px;
	}
	::v-deep .cs-button-container {
		&:nth-child(even) {
			a,
			span:not(.line-item-content span) {
				background-color: $theme_color;
			}
		}

		a,
		span:not(.line-item-content span) {
			padding: 20px;
			text-decoration: none;
			display: flex;
			gap: 20px;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
			@media (max-width: 767px) {
				flex-direction: column;
			}

			.number {
				font-weight: $theme_bold_text;
				font-size: 36px;
				min-width: 50px;
				line-height: 1;
				display: flex;
				justify-content: flex-end;
				color: $bg_dark;
				@media (max-width: 767px) {
					margin: 0 auto;
					justify-content: center;
				}
			}
			.line-item-content {
				width: 100%;
				h4 {
					@include small-title;
					margin-bottom: 15px;
				}

				span {
					@include text;

                    *:last-child {
                        margin-bottom: 0;
                    }
				}
			}
		}
	}
}
</style>
